// 数据colom
export default {
  goods: {
    columns: [
      {
        title: '商品主图',
        dataIndex: 'goods_image',
        key: '_0',
        ellipsis: true,
        // width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '商品名称',
        dataIndex: 'goods_name',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_bn',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        // title: '商品类型',
        slots: { title: 'shopstype' },
        dataIndex: 'goods_type',
        key: '_5',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return record.permission_type == 1 ? '菜单' : '权限'
        // },
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '商城售价',
        dataIndex: 'goods_price',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '库存',
        dataIndex: 'goods_stock',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      {
        title: '实际销量',
        dataIndex: 'goods_sales',
        key: '_9',
        ellipsis: true,
        customRender: (name, record) => {
          return record.permission_params == 1 ? '菜单' : '权限'
        },
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '商城分类',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        // title: '上架时间',
        slots: { title: 'uptime' },
        dataIndex: 'permission_is_show',
        key: '_11',
        ellipsis: true,
        scopedSlots: { customRender: '_11' },
      },
      {
        // title: '状态',
        slots: { title: 'status' },
        dataIndex: 'permission_is_show',
        key: '_12',
        ellipsis: true,
        scopedSlots: { customRender: '_12' },
      },
      {
        title: '运费设置',
        dataIndex: 'delivery_money',
        key: '_13',
        ellipsis: true,
        scopedSlots: { customRender: '_13' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '220px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  carddateils: {
    columns: [
      {
        title: '产品名称',
        dataIndex: 'goods_name',
        key: '_0',
        ellipsis: true,
        // width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '商品码',
        dataIndex: 'goods_id',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '标准单价',
        dataIndex: 'goods_price',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '类型',
        // slots: { title: 'shopstype' },
        dataIndex: 'goods_type',
        key: '_5',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return record.permission_type == 1 ? '菜单' : '权限'
        // },
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '分类',
        dataIndex: 'permission_component',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '品牌',
        dataIndex: 'brand_name',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
    ]
  },
  plandateils: {
    columns: [
      {
        title: '商品名称',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        // width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '标准单价',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '类型',
        dataIndex: 'permission_icon',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '最多消耗量',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '数量',
        dataIndex: 'permission_component',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
    ]
  },
  saleslip: {
    columns: [
      {
        title: '客户信息',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '销售单号',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '商品总价',
        dataIndex: 'permission_icon',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '实付金额',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      // 门店
      {
        slots: { title: 'shop' },
        dataIndex: 'permission_type',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      // 拿货方式
      {
        slots: { title: 'getways' },
        dataIndex: 'permission_type',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      // 订单时间
      {
        slots: { title: 'ordertimes' },
        dataIndex: 'permission_type',
        key: '_9',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '状态',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  // 自提商品
  selfpickou: {
    columns: [
      {
        title: '客户信息',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_0' },
      },
      {
        slots: { title: 'uptime' },
        // title: '取货时间',
        dataIndex: 'permission_name',
        key: '_1',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '取货数量',
        dataIndex: 'permission_icon',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '取货员工',
        // slots: { title: 'shopstype' },
        dataIndex: 'permission_type',
        key: '_3',
        ellipsis: true,
        // customRender: (name, record) => {
        //   return record.permission_type == 1 ? '菜单' : '权限'
        // },
        scopedSlots: { customRender: '_3' },
      },
      {
        // title: '门店', 
        slots: { title: 'permission_component' },
        dataIndex: 'permission_component',
        key: '_4',
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {
        // title: '取货渠道', 
        slots: { title: 'shopstype' },
        dataIndex: 'shopstype',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: '175px',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  // 消耗单
  verificationservice: {
    columns: [

      {
        slots: { title: 'uptime' },
        // title: '开单时间',
        dataIndex: 'permission_name',
        key: '_0',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '消耗单号',
        dataIndex: 'goods_bn',
        key: '_1',
        ellipsis: true,
        // width: 180,
        scopedSlots: { customRender: '_1' },
      },
      {
        // title: '消耗门店',
        slots: { title: 'permission_component' },
        dataIndex: 'permission_component',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {//todo
        title: '客户信息',
        dataIndex: 'permission_type',
        key: '_3',
        ellipsis: true,
        width: 180,
        // customRender: (name, record) => {
        //   return record.permission_type == 1 ? '菜单' : '权限'
        // },
        scopedSlots: { customRender: '_3' },
      },

      {
        // title: '取货渠道', 
        slots: { title: 'shopstype' },
        dataIndex: 'shopstype',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },

      {
        // title: '消耗单状态', 
        slots: { title: 'shopstypestatus' },
        dataIndex: 'shopstypestatus',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '美容师',
        dataIndex: 'goods_name',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },

      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: '205px',
        className: 'action_flex ',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  malluserslist: {
    columns: [
      {
        title: 'UID',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '用户昵称(姓名)',
        dataIndex: 'goods_bn',
        key: '_1',
        ellipsis: true,
        // width: 180,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '手机号',
        // slots: { title: 'permission_component' },
        dataIndex: 'permission_component',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {//todo
        title: '钱包余额',
        dataIndex: 'permission_type',
        key: '_3',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '累计商城支付金额',
        dataIndex: 'shopstype',
        key: '_5',
        ellipsis: true,
        scopedSlots: { customRender: '_5' },
      },

      {
        title: '支付订单数',
        // slots: { title: 'shopstypestatus' },
        dataIndex: 'shopstypestatus',
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '首次注册门店',
        dataIndex: 'goods_name',
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },

      },
      {
        title: '上次活跃时间',
        dataIndex: 'goods_id',
        key: '_9',
        ellipsis: true,
        scopedSlots: { customRender: '_9' },
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.goods_id - b.goods_id,
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        className: 'action_flex ',
        scopedSlots: { customRender: '_7' },
      },
    ]
  },
  // 用户资产
  userassets: {
    columns: [
      {
        title: '商品名称/规格',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '寄存总数量',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '客户带走总数量',
        dataIndex: 'permission_icon',
        key: '_4',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '在店消耗总数量',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      // 门店
      {
        title: '不可用总数量',
        dataIndex: 'shop',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      // 拿货方式
      {
        title: '剩余寄存总数量',
        dataIndex: 'permission_type',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      // 订单时间
      {
        slots: { title: 'ordertimes' },
        dataIndex: 'permission_type',
        key: '_9',
        ellipsis: true,
        width: 180,
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '状态',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns_service: [
      {
        title: '商品名称/规格',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '商城累计支付金额',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_3' },
      },

      {//todo
        title: '购买总数量',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      // 门店
      {
        title: '已消耗总数量',
        dataIndex: 'goods_id',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      // 拿货方式
      {
        title: '不可用总数量',
        dataIndex: 'permission_type',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      {
        title: '剩余可消耗总数量',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        scopedSlots: { customRender: '_10' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns_card: [
      {
        title: '商品名称',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '商城累计支付金额',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '购买总数量',
        dataIndex: 'permission_icon',
        key: '_4',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '已失效总数量',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      // 门店
      {
        title: '使用中总数量',
        dataIndex: 'shop',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns_plan: [
      {
        title: '商品名称',
        dataIndex: 'permission_id',
        key: '_0',
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '商城累计支付金额',
        dataIndex: 'permission_name',
        key: '_3',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '客户带走总数量',
        dataIndex: 'permission_icon',
        key: '_4',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '购买总数量',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      // 门店
      {
        title: '待激活总数量',
        dataIndex: 'shop',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      // 拿货方式
      {
        title: '已停卡总数量',
        dataIndex: 'permission_type',
        sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      // 订单时间
      {
        title: '已失效总数量',
        dataIndex: 'permission_type',
        key: '_9',
        ellipsis: true,
        width: 180,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '使用中总数量',
        dataIndex: 'permission_is_show',
        key: '_10',
        ellipsis: true,
        sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_10' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    // 用户详情-产品 服务 储蓄卡 方案
    columns_details_4: [
      {
        title: '购买门店',
        dataIndex: 'shop_name',
        key: '_0',
        ellipsis: true,
        // width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '用户',
        dataIndex: 'permission_name',
        key: '_1',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '支付金额',
        dataIndex: 'price',
        key: '_2',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '购买数量',
        dataIndex: 'num',
        key: '_4',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '已失效',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '使用中',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns_details_3: [
      {
        title: '购买门店',
        dataIndex: 'shop_name',
        key: '_0',
        ellipsis: true,
        // width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '用户',
        dataIndex: 'permission_name',
        key: '_1',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '支付金额',
        dataIndex: 'price',
        key: '_2',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '购买数量',
        dataIndex: 'num',
        key: '_4',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '已失效',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '使用中',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns_details_2: [
      {
        title: '购买门店',
        dataIndex: 'shop_name',
        key: '_0',
        ellipsis: true,
        // width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '用户',
        dataIndex: 'permission_name',
        key: '_1',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '支付金额',
        dataIndex: 'price',
        key: '_2',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '购买总数量',
        dataIndex: 'num',
        key: '_4',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '已消耗',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '不可用',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },
      {
        title: '剩余可消耗',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      {
        title: '最近购买时间',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_9',
        ellipsis: true,
        scopedSlots: { customRender: '_9' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
    columns_details_1: [
      {
        title: '购买门店',
        dataIndex: 'shop_name',
        key: '_0',
        ellipsis: true,
        // width: 200,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '用户',
        dataIndex: 'permission_name',
        key: '_1',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '寄存总数量',
        dataIndex: 'save_num',
        key: '_2',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_2' },
      },
      {
        title: '客户带走',
        dataIndex: 'price',
        key: '_3',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '在店消耗',
        dataIndex: 'num',
        key: '_4',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_4' },
      },
      {//todo
        title: '不可用',
        dataIndex: 'permission_type',
        key: '_5',
        ellipsis: true,
        // sorter: (a, b) => a.goods_id - b.goods_id,
        scopedSlots: { customRender: '_5' },
      },
      {
        title: '剩余寄存',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_6',
        ellipsis: true,
        scopedSlots: { customRender: '_6' },
      },//最近购买时间
      {
        title: '最近购买时间',
        dataIndex: 'shop',
        // sorter: (a, b) => a.goods_id - b.goods_id,
        key: '_8',
        ellipsis: true,
        scopedSlots: { customRender: '_8' },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        // width: '334px',
        scopedSlots: { customRender: '_7' },
      },
    ],
  },


}